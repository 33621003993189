<template>
  <div class="fitment-add-goods">
    <van-nav-bar
      title="添加商品"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    >
    </van-nav-bar>

    <div style="display: flex; height: calc(100vh - 46px)">
      <van-sidebar v-model="activeKey">
        <van-sidebar-item
          v-for="(item, index) in classificationList"
          :key="index"
          :title="item.name"
          @click="sidebarClick(item)"
          :style="item.lock ? 'color: #ccc' : ''"
        />
      </van-sidebar>

      <div class="goods-list">
        <van-empty description="请选择类目" v-if="beforeGoods" />
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div v-for="(item, index) in list" :key="index" class="goods-item">
            <van-image width="80px" height="80px" :src="item.images[0]" />
            <div class="item-right">
              <div class="name van-multi-ellipsis--l2">
                {{ item.subject }}
              </div>
              <div class="right-bottom">
                <div>
                  <span style="color: #999">预估可获利</span>
                  &nbsp;
                  <span style="color: #a80000">
                    <span>￥</span>
                    <span>{{ (item.salePrice - item.price).toFixed(2) }}</span>
                  </span>
                </div>
                <div>
                  <van-button
                    v-if="item.chosen === true"
                    plain
                    size="mini"
                    color="#999"
                    style="width: 56px"
                    @click="cancelChoose(item)"
                    >已选择</van-button
                  >
                  <van-button
                    v-else-if="item.lock"
                    size="mini"
                    color="#FF5200"
                    style="width: 56px"
                    @click="unlockGoods(item)"
                    >添加</van-button
                  >
                  <van-button
                    v-else-if="item.chosen === false"
                    plain
                    size="mini"
                    color="#FF5200"
                    style="width: 56px"
                    @click="choose(item)"
                    :disabled="multiple && multipleData.length >= multipleMax"
                    >选择</van-button
                  >
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassification, getProduct, unlock } from "@/api/fitment";
export default {
  name: "fitmentAddGoods",
  data() {
    return {
      activeKey: 0,
      classificationList: [], // 类目列表
      id: null, //商品的类目id
      loading: false,
      finished: false,
      list: [], // 商品列表
      total: 0, //商品总量
      page: 0, // 商品分页
      multiple: false, //是否多选
      index: false,
      multipleData: [], //多选数据
      multipleMax: 0, //多选上限
      beforeGoods: true,
    };
  },
  created() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
    }
    if (this.$route.query.multiple) {
      this.multiple = true;
      this.multipleData = JSON.parse(this.$route.query.data);
      this.multipleMax = +this.$route.query.max;
    }
    // this.getClassification(true);
    this.getClassification();
  },
  methods: {
    // 获取分类
    getClassification() {
      getClassification().then((response) => {
        getClassification({ size: response.data.data.total }).then((res) => {
          console.log(res);
          const result = res.data.data;
          result.records.map((x) => {
            x.lock = true;
            this.$store.state.fitment.unlockedCates.map((i) => {
              if (i.unlockId === x.id) {
                x.lock = false;
              }
            });
          });
          this.classificationList = result.records;
          // if (flag) {
          //   this.id = result.records[0].id;
          //   // this.onLoad();
          // }
        });
      });
    },
    // 获取商品
    getProduct() {
      getProduct({ current: this.page, businessCategoryId: this.id }).then(
        (res) => {
          console.log(res);
          const result = res.data.data;
          result.records.map((x) => {
            x.chosen = false;
            x.lock = true;
            this.multipleData.map((i) => {
              if (i.id == x.productId) {
                x.chosen = true;
              }
            });
            this.$store.state.fitment.unlockedGoods.map((i) => {
              if (i.unlockId == x.id) {
                x.lock = false;
              }
            });
          });
          this.list.push(...result.records);
          this.total = result.total;

          this.loading = false;
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      );
    },
    // 侧边栏点击事件
    sidebarClick(item) {
      if (item.lock) {
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "是否支付" + item.expense + "创客金币开通" + item.name + "分类？",
          })
          .then(() => {
            console.log("confirm");
            unlock({
              unlockType: "2", // 写死
              unlockId: item.id,
              // modulesType: this.modulesType,
              gold: item.expense,
            }).then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$toast("解锁成功");
                this.$store.dispatch("getUnlockedCates").then(() => {
                  this.getClassification();
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.beforeGoods = false;
        this.id = item.id;
        this.list = [];
        this.finished = false;
        this.page = this.total = 0;
        this.onLoad();
      }
    },
    // 获取list数据
    onLoad() {
      this.loading = true;
      if (this.id) {
        this.page++;
        this.getProduct();
      }
    },
    unlockGoods(item) {
      console.log("unlock", item);
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否支付" + item.buyPrice + "创客金币开通该商品？",
        })
        .then(() => {
          console.log("confirm");
          unlock({
            unlockType: "3", // 写死
            unlockId: item.id,
            // modulesType: this.modulesType,
            gold: item.buyPrice,
          }).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$toast("解锁成功");
              this.$store.dispatch("getUnlockedGoods").then(() => {
                this.list = [];
                this.getProduct();
              });
            }
          });
        })
        .catch(() => {});
    },
    // 选择商品
    choose(item) {
      if (this.multiple) {
        console.log("多选");
        let flag = true;
        this.$store.getters.goodsCaseUsable.map((x) => {
          if (x.id == item.productId) {
            flag = false;
          }
        });
        if (flag) {
          item.chosen = true;
          let obj = {
            id: item.productId,
            subject: item.subject,
            images: item.images,
            sales: item.saleNum || "--",
            salePrice: item.salePrice || "--",
          };
          this.multipleData.push(obj);
        } else {
          this.$toast("已在其他模块被选中");
        }
      } else {
        console.log("单选");
        this.$store.commit("chooseLink", {
          type: "goods",
          index: this.index,
          value: {
            id: item.productId,
            name: item.subject,
          },
        });
        this.$router.back();
      }
    },
    // 取消选择 (多选)
    cancelChoose(item) {
      console.log("取消选择");
      item.chosen = false;
      this.multipleData = this.multipleData.filter((x) => {
        return x.id != item.productId;
      });
    },
    // 右上角完成
    complete() {
      console.log(this.multipleData);
      this.$store.commit("chooseLink", {
        value: this.multipleData,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-add-goods {
  background-color: #f6f6f6;
  .van-sidebar {
    width: 85px;
    height: 100%;
    .van-sidebar-item {
      padding: 10px 12px;
      text-align: center;
    }
    /deep/ .van-sidebar-item--select {
      &:hover {
        color: #323233;
      }
      &::before {
        height: 100%;
        background-color: #ff5200;
      }
    }
  }
  .goods-list {
    flex: 1;
    background-color: #fff;
    height: calc(100vh - 46px);
    overflow: auto;
    .goods-item {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      padding: 10px;
      .van-image {
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
      }
      .item-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .right-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>